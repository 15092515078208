@import '../../../../../../styles/shared';

@include hub-dark-theme() {
  .largeImagesWrapper {
    background: var(--hub-carousel-module-background-color);
  }
}

@include hub-dark-theme() {
  .imageAndTextWrapper {
    background: var(--hub-carousel-module-background-color);
  }
}

.removeBottomPadding {
  padding-bottom: 0;
}
