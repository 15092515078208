.labelTitle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  margin-bottom: 50px;
  padding: 0 16px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 24px;
}

.title {
  font-family: var(--hub-module-heading-font-family);
  font-size: 40px;
  line-height: 48px;
}

.text {
  font-size: 24px;
  font-weight: 300;
}

.title + .text {
  margin-top: 20px;
}

.ctaWrapper {
  margin-top: 30px;
}
