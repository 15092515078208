@import '../../../../../../../styles/shared';

.headerWrapper {
  margin-bottom: 60px;

  @include mq($from: desktop) {
    margin-bottom: 80px;
  }
}

.contentSliderWrapper {
  position: relative;
  z-index: 1;

  :global(.slick-list) {
    overflow: hidden;
  }

  :global(.slick-track) {
    @include mq($from: tablet) {
      gap: 0;
    }
  }

  :global(.slick-slide[aria-hidden='true']) {
    visibility: hidden;
  }
}

.imageSliderWrapper {
  @include mq($until: tablet) {
    margin-top: 30px;
  }

  :global(.slick-track) {
    align-items: flex-end;
    gap: 0;
  }

  :global(.slick-slide) {
    padding: 0;
    overflow: hidden;
  }

  :global(.slick-arrow) {
    top: 60%;

    @include mq($from: tablet) {
      top: 80%;
    }
  }

  :global(.slick-prev) {
    left: 0;
  }

  :global(.slick-next) {
    right: 0;
  }
}
