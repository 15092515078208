@import '../../../../../../../styles/shared';

.wrapper {
  @include mq($until: desktop) {
    padding-bottom: 30px;
  }
}

.slide {
  margin: 0 10px;
}

.headerWrapper {
  margin-bottom: 60px;

  @include mq($from: desktop) {
    margin-bottom: 80px;
  }
}
