@import '../../../../../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--hub-carousel-module-slide-background-color);
  border-radius: 16px;
  overflow: hidden;

  @include mq($from: wide) {
    min-height: 630px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: flex-end;
  margin: -75px -35px 0 0;

  @include mq($from: wide) {
    margin: -120px -57px -10px 0;
  }
}

.image {
  display: block;
  width: 295px;
  height: 295px;
  border-radius: 50%;
  object-fit: cover;

  @include mq($from: wide) {
    width: 475px;
    height: 475px;
  }
}

.fakeImage {
  @extend .image;
  background: #c4c4c4;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0;
  gap: 30px;
  padding: 0 25px 100px;
  color: #fff;

  @include mq($from: desktop) {
    gap: 80px;
    padding: 0 40px 40px;
  }
}

.title {
  font-family: var(--hub-module-heading-font-family);
  font-size: 18px;
  font-weight: 300;
  line-height: 48px;

  @include mq($from: wide) {
    font-size: 22px;
  }
}

.text {
  font-family: var(--hub-module-heading-font-family);
  font-size: 24px;

  @include mq($from: wide) {
    font-size: 32px;
  }

  p {
    line-height: 32px;

    @include mq($from: wide) {
      line-height: 48px;
    }
  }
}

.cta {
  color: #fff;
}
