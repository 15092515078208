@import '../../../../../../../../styles/shared';

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: -50%;
  border-radius: 50%;
}

.image {
  width: 320px;
  background-color: #c4c4c4;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  transition: opacity 1s;

  @include mq($from: tablet) {
    width: 265px;
  }

  @include mq($from: 1440px) {
    width: 530px;
  }
}

:global(.slick-current) {
  .image {
    opacity: 1;

    @include mq($from: tablet) {
      width: 417px;
    }

    @include mq($from: 1440px) {
      width: 834px;
    }
  }
}
